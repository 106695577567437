@import '~normalize.css/normalize.css';
@import "../../node_modules/angular-calendar/css/angular-calendar.css";
@import 'variables';

/* You can add global styles to this file, and also import other style files */

html, body {
    height: 100%;
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    color: $accent-color;
}

h1, h2, h3, h4 {
    margin: 0 0 12px;
    line-height: 1em;
}

h1,
.mat-toolbar h1 {
    font-size: 24px;
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}

fieldset {
    border: 1px solid $border-color;

    legend {
        padding: 8px;

        h3 {
            margin: 0;
        }
    }

    .mat-list {
        padding: 0;
    }
}


// Hide arrows
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
// Firefox
input[type=number] {
    -moz-appearance:textfield;
}

.mat-tooltip {
    font-size: 0.9em !important;
}

.mat-table {
    width: 100%;

    th {
        color: $accent-color;
    }
}

.mat-menu-panel {
    min-height: 50px !important;
}

.mat-menu-panel.notif-menu {
    width: 400px;
    max-width: 400px !important;
}

.mat-toolbar .mat-spinner circle {
    stroke: $contrast-color;
}
