@use '@angular/material' as mat;
@import '@angular/material/theming';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$app-primary: mat.define-palette(mat.$blue-gray-palette);
$app-accent:  mat.define-palette(mat.$indigo-palette, 500, 100, 700);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);
$app-success: mat.define-palette(mat.$green-palette);
$app-info: mat.define-palette(mat.$blue-palette);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

// Create our Sass color vars (will be available in all the project)
$primary-color: mat.get-color-from-palette($app-primary);
$primary-dark: mat.get-color-from-palette($app-primary, darker);
$primary-light: mat.get-color-from-palette($app-primary, lighter);

$accent-color: mat.get-color-from-palette($app-accent);
$accent-dark: mat.get-color-from-palette($app-accent, darker);
$accent-light: mat.get-color-from-palette($app-accent, lighter);

$warn-color: mat.get-color-from-palette($app-warn);
$warn-dark: mat.get-color-from-palette($app-warn, darker);
$warn-light: mat.get-color-from-palette($app-warn, lighter);

$success-color: mat.get-color-from-palette($app-success);
$success-dark: mat.get-color-from-palette($app-success, darker);
$success-light: mat.get-color-from-palette($app-success, lighter);

$info-color: mat.get-color-from-palette($app-info);
$info-dark: mat.get-color-from-palette($app-info, darker);
$info-light: mat.get-color-from-palette($app-info, lighter);

$border-color: rgba(0, 0, 0, 0.12);
$contrast-color: #fff;
